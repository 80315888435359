import { Component, OnInit, ElementRef, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import * as SecureLS from "secure-ls";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { MatSnackBar, MatDialog } from "@angular/material";
import * as password from "secure-random-password";
import { GlobalVariable } from "src/app/config/global";
import { BusinessUnit } from "src/app/model/businessUnit";
import { BusinessunitService } from "src/app/service/businessunit.service";
import { DistrictService } from "src/app/service/district.service";
import { District } from "src/app/model/district";
import { Church } from "src/app/model/church";
import { ChurchService } from "src/app/service/church.service";
import { UnionService } from "src/app/service/union.service";
import { Union } from "src/app/model/union";
import { PaymentService } from "src/app/service/payment.service";
import { invalid } from "@angular/compiler/src/render3/view/util";
import { ConfirmpaymentDialogComponent } from "../confirmpayment-dialog/confirmpayment-dialog.component";
import { ChurchSearchComponent } from "../church-search/church-search.component";
import { Donor } from "src/app/model/donor";
import { DonorService } from "src/app/service/donor.service";

const ePaycoURL =
  "https://s3-us-west-2.amazonaws.com/epayco/v1.0/checkoutEpayco.js";
const wompiURL = "https://checkout.wompi.co/widget.js";
@Component({
  selector: "app-myenvelope",
  templateUrl: "./myenvelope.component.html",
  styleUrls: ["./myenvelope.component.scss"],
})
export class MyenvelopeComponent implements OnInit {
  AppName = "";
  isLoading = false;
  errorMessage = "";
  envelopeForm: FormGroup;
  unionSearch: String = "";
  ls: SecureLS;
  @Input() iglesiaSeleccionada: Church;
  // params
  unionParameter;
  businessUnitParameter;
  districtParameter;
  churchParameter;
  // donor information
  donor: Donor;
  // model
  unions: Union[] = [];
  selectedUnion = <Union>{
    id: "",
  };
  businessUnits: BusinessUnit[] = [];
  selectedBusinessUnit = <BusinessUnit>{
    id: "",
  };
  districts: District[];
  selectedDistrict = <District>{
    id: "",
  };
  churchs: Church[];
  selectedChurch = <Church>{
    id: "",
  };
  // transaction
  totalOfferings = 0;
  totalTransaction = 0;
  // ePayco
  buttonIndex = 7;
  loadAPI: Promise<any>;
  secretIntegrityHash: string = "";

  constructor(
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private donorService: DonorService,
    private unionService: UnionService,
    private businessunitService: BusinessunitService,
    private districtService: DistrictService,
    private churchService: ChurchService,
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.AppName = GlobalVariable.APP_NAME;
    this.ls = new SecureLS({ encodingType: "aes" });
    this.initForms();
    this.getParams();
    if (this.validateSession()) {
      this.getDonorData();
    }
    this.getAllUnion();
  }

  initForms() {
    this.envelopeForm = this.formBuilder.group({
      union: new FormControl("", [Validators.required]),
      bu: new FormControl("", [Validators.required]),
      district: new FormControl("", [Validators.required]),
      church: new FormControl("", [Validators.required]),
      firstName: new FormControl("", [Validators.required]),
      lastName: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.email]),
      document: new FormControl("", [Validators.required]),
      phoneNumber: new FormControl("", [Validators.required]),
      tithes: new FormControl(),
      offeringsGeneral: new FormControl(),
      offeringsFirst: new FormControl(),
      offerings13th: new FormControl(),
      offeringsInvestment: new FormControl(),
      offeringsSabbathSchool: new FormControl(),
      offeringsBirthday: new FormControl(),
      offeringsGratitude: new FormControl(),
      offerings: new FormControl({
        value: this.totalOfferings,
        disabled: true,
      }),
      offeringsTemple: new FormControl(),
      offeringsRadioTV: new FormControl(),
      offeringsADRA: new FormControl(),
      offeringsOther: new FormControl(),
      offeringsOtherDescription: new FormControl({
        value: "",
        disabled: false,
      }),
      totalTithesOfferings: new FormControl({
        value: this.totalTransaction,
        disabled: true,
      }),
      privacy: new FormControl(false, [Validators.requiredTrue]),
    });
  }

  getErrorMessage(component: string) {
    let errorMessage = "";
    switch (component) {
      case "union":
        errorMessage = this.envelopeForm.get("union").hasError("required")
          ? "debe seleccionar su unión"
          : "";
        break;
      case "bu":
        errorMessage = this.envelopeForm.get("bu").hasError("required")
          ? "debe seleccionar su campo"
          : "";
        break;
      case "district":
        errorMessage = this.envelopeForm.get("district").hasError("required")
          ? "debe seleccionar su distrito"
          : "";
        break;
      case "church":
        errorMessage = this.envelopeForm.get("church").hasError("required")
          ? "debe seleccionar su iglesia"
          : "";
        break;
      case "firstName":
        errorMessage = this.envelopeForm.get("firstName").hasError("required")
          ? "debe ingresar su nombre"
          : "";
        break;
      case "lastName":
        errorMessage = this.envelopeForm.get("lastName").hasError("required")
          ? "debe ingresar sus apellidos"
          : "";
        break;
      case "email":
        errorMessage = this.envelopeForm.get("email").hasError("required")
          ? "campo requerido"
          : this.envelopeForm.get("email").hasError("email")
          ? "ingresa un correo válido"
          : "";
        break;
      case "document":
        errorMessage = this.envelopeForm.get("document").hasError("required")
          ? "debe ingresar su documento de identificación"
          : "";
        break;
      case "phoneNumber":
        errorMessage = this.envelopeForm.get("phoneNumber").hasError("required")
          ? "debe ingresar su número de teléfono"
          : "";
        break;
      case "offeringsOtherDescription":
        errorMessage = this.envelopeForm
          .get("offeringsOtherDescription")
          .hasError("required")
          ? "campo requerido"
          : "";
        break;
      case "privacy":
        errorMessage = this.envelopeForm.get("privacy").hasError("required")
          ? "Debe aceptar términos y condiciones para poder continuar"
          : "";
        break;
      case "password":
        errorMessage = this.envelopeForm.get("password").hasError("required")
          ? "campo requerido"
          : "";
        break;
    }
    return errorMessage;
  }

  getParams() {
    this.route.paramMap.subscribe((params) => {
      this.unionParameter = params.get("u");
      this.businessUnitParameter = params.get("bu");
      this.districtParameter = params.get("d");
      this.churchParameter = params.get("c");
    });
  }

  setPredefinedParams(type: string) {
    switch (type) {
      case "union":
        this.selectedUnion = this.unions.find(
          (u) => u.id.trim() === this.unionParameter
        );
        if (this.selectedUnion) {
          this.envelopeForm.controls["union"].setValue(this.selectedUnion);
          this.envelopeForm.controls["union"].enable();
          this.getBusinessUnitByUnion();
        }
        break;
      case "businessunit":
        this.selectedBusinessUnit = this.businessUnits.find(
          (bu) => bu.id.trim() === this.businessUnitParameter
        );
        if (this.selectedBusinessUnit) {
          this.envelopeForm.controls["bu"].setValue(this.selectedBusinessUnit);
          this.envelopeForm.controls["bu"].enable();
          this.getDistricsByBU();
        }
        break;
      case "district":
        this.selectedDistrict = this.districts.find(
          (d) => d.id.trim() === this.districtParameter
        );
        if (this.selectedDistrict) {
          this.envelopeForm.controls["district"].setValue(
            this.selectedDistrict
          );
          this.envelopeForm.controls["district"].enable();
          this.getChurchsByDistrict();
        }
        break;
      case "church":
        this.selectedChurch = this.churchs.find(
          (c) => c.id.trim() === this.churchParameter
        );
        if (this.selectedChurch) {
          this.envelopeForm.controls["church"].setValue(this.selectedChurch);
          this.envelopeForm.controls["church"].enable();
        }
        break;
      default:
        break;
    }
  }

  validateSession(): boolean {
    if (localStorage.getItem("auid") === null) {
      this.buttonIndex = 6;
      return false;
    } else {
      this.buttonIndex = 8;
      return true;
    }
  }

  /** function to get the current logged in user*/
  getDonorData() {
    this.donor = <Donor>{
      id: "",
    };
    const userid = this.ls.get("auid");
    this.donorService.getDonor(userid).subscribe(
      (p) => {
        this.donor = p.results !== undefined ? p.results[0] : null;
      },
      (e) => this.launchMessage(e),
      () => {
        if (this.donor) {
          //set donor church
          this.setDonorChurchParams();
          //set donor values
          this.envelopeForm.controls["firstName"].setValue(
            this.donor.firstname !== ""
              ? this.donor.firstname + " " + this.donor.middlename
              : this.donor.org_name
          );
          this.envelopeForm.controls["lastName"].setValue(
            this.donor.first_lastname !== ""
              ? this.donor.first_lastname + " " + this.donor.second_lastname
              : " "
          );
          this.envelopeForm.controls["email"].setValue(this.donor.email);
          this.envelopeForm.controls["document"].setValue(this.donor.id);
          this.envelopeForm.controls["phoneNumber"].setValue(
            this.donor.cellphone
          );
        }
      }
    );
  }

  setDonorChurchParams() {
    this.unionParameter = this.donor.union_code;
    this.businessUnitParameter = this.donor.bu_code;
    this.districtParameter = this.donor.district_id;
    this.churchParameter = this.donor.church_id;
  }

  getAllUnion() {
    this.isLoading = true;
    this.unionService.get().subscribe(
      (p) => {
        this.unions = p.results !== undefined ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        this.unionParameter = "AGU111";
        if (this.unions.length > 0 && this.unionParameter) {
          this.setPredefinedParams("union");
          this.unionParameter = null;
        }
      }
    );
  }

  /** Event handler when business unit select component changes */
  unionSelectionChange(event: { value: Union }) {
    this.selectedUnion = event.value;
    this.envelopeForm.controls["bu"].setValue(null);
    this.envelopeForm.controls["district"].setValue(null);
    this.envelopeForm.controls["church"].setValue(null);
    this.businessUnits = [];
    this.districts = [];
    this.churchs = [];
    this.getBusinessUnitByUnion();
  }

  getBusinessUnitByUnion() {
    this.isLoading = true;
    this.businessunitService.getByUnion(this.selectedUnion.id).subscribe(
      (p) => {
        this.businessUnits = p.results !== undefined ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.businessUnits.length > 0 && this.businessUnitParameter) {
          this.setPredefinedParams("businessunit");
          this.businessUnitParameter = null;
        }
      }
    );
  }

  /** Event handler when business unit select component changes */
  buSelectionChange(event: { value: BusinessUnit }) {
    this.selectedBusinessUnit = event.value;
    this.envelopeForm.controls["district"].setValue(null);
    this.envelopeForm.controls["church"].setValue(null);
    this.districts = [];
    this.churchs = [];
    // reset payment buttons to update public key
    document.getElementsByTagName("form")[1].innerHTML = "";
    document.getElementsByTagName("form")[2].innerHTML = "";
    this.getDistricsByBU();
  }

  getDistricsByBU() {
    this.isLoading = true;
    this.districtService.getByBU(this.selectedBusinessUnit.id).subscribe(
      (p) => {
        this.districts = p.results !== undefined ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.districts.length > 0 && this.districtParameter) {
          this.setPredefinedParams("district");
          this.districtParameter = null;
        }
      }
    );
  }

  /** Event handler when district select component changes */
  districtSelectionChange(event: { value: District }) {
    this.selectedDistrict = event.value;
    this.envelopeForm.controls["church"].setValue(null);
    this.churchs = [];
    // reset payment buttons to update public key
    document.getElementsByTagName("form")[1].innerHTML = "";
    document.getElementsByTagName("form")[2].innerHTML = "";
    this.getChurchsByDistrict();
  }

  getChurchsByDistrict() {
    this.isLoading = true;
    this.churchService.getByDistrict(this.selectedDistrict.id).subscribe(
      (p) => {
        this.churchs = p.results !== undefined ? p.results : [];
      },
      (e) => this.launchMessage(e),
      () => {
        this.isLoading = false;
        if (this.churchs.length > 0 && this.churchParameter) {
          this.setPredefinedParams("church");
          this.churchParameter = null;
        }
      }
    );
  }

  /** Event handler when church select component changes */
  churchSelectionChange(event: { value: Church }) {
    this.selectedChurch = event.value;
    // reset payment buttons to update public key
    document.getElementsByTagName("form")[1].innerHTML = "";
    document.getElementsByTagName("form")[2].innerHTML = "";
  }

  calculateTotal(form: FormGroup) {
    if (
      form.value.tithes !== null ||
      form.value.offeringsGeneral !== null ||
      form.value.offeringsFirst !== null ||
      form.value.offerings13th !== null ||
      form.value.offeringsInvestment !== null ||
      form.value.offeringsSabbathSchool !== null ||
      form.value.offeringsBirthday !== null ||
      form.value.offeringsGratitude !== null ||
      form.value.offeringsTemple !== null ||
      form.value.offeringsRadioTV !== null ||
      form.value.offeringsADRA !== null ||
      form.value.offeringsOther !== null
    ) {
      this.totalTransaction =
        form.value.tithes +
        form.value.offeringsGeneral +
        form.value.offeringsFirst +
        form.value.offerings13th +
        form.value.offeringsInvestment +
        form.value.offeringsSabbathSchool +
        form.value.offeringsBirthday +
        form.value.offeringsGratitude +
        form.value.offeringsTemple +
        form.value.offeringsRadioTV +
        form.value.offeringsADRA +
        form.value.offeringsOther;
    } else {
      this.totalTransaction = 0;
    }
    if (
      form.value.offeringsGeneral !== null ||
      form.value.offeringsFirst !== null ||
      form.value.offerings13th !== null ||
      form.value.offeringsInvestment !== null ||
      form.value.offeringsSabbathSchool !== null ||
      form.value.offeringsBirthday !== null ||
      form.value.offeringsGratitude !== null
    ) {
      this.totalOfferings =
        form.value.offeringsGeneral +
        form.value.offeringsFirst +
        form.value.offerings13th +
        form.value.offeringsInvestment +
        form.value.offeringsSabbathSchool +
        form.value.offeringsBirthday +
        form.value.offeringsGratitude;
    } else {
      this.totalOfferings = 0;
    }
    if (form.value.offeringsOther !== null && form.value.offeringsOther > 0) {
      this.envelopeForm.controls["offeringsOtherDescription"].setValidators([
        Validators.required,
      ]);
      this.envelopeForm.controls[
        "offeringsOtherDescription"
      ].updateValueAndValidity();
    } else {
      this.envelopeForm.controls["offeringsOtherDescription"].setValidators([]);
      this.envelopeForm.controls[
        "offeringsOtherDescription"
      ].updateValueAndValidity();
    }
  }

  createTransaction(form: FormGroup) {
    if (form.valid) {
      // reset payment buttons to update public key
      document.getElementsByTagName("form")[2].innerHTML = "";
      if (this.isValidAmount()) {
        const dialogRef = this.dialog.open(ConfirmpaymentDialogComponent, {
          width: "450px",
          data: {
            name: this.envelopeForm.value.firstName,
            totalTransaction: this.totalTransaction,
            churchName: this.selectedChurch.name,
            districtName: this.selectedDistrict.name,
            businessUnitName: this.selectedBusinessUnit.short_name,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.loadAPI = new Promise((resolve) => {
              this.loadWompiScript();
            });
          }
        });
      } else {
        this.launchMessage(
          "El monto total de tu donación debe ser de mínimo $10,000 COP"
        );
      }
    } else {
      let invalidFields = [].slice.call(
        document.getElementsByClassName("ng-invalid")
      );
      if (invalidFields.length > 2) {
        invalidFields[2].focus();
      } else {
        invalidFields[1].focus();
      }
    }
  }

  isValidAmount() {
    if (this.totalTransaction >= 10000) {
      return true;
    } else {
      return false;
    }
  }

  loadScript() {
    let node = document.createElement("script");
    node.src = ePaycoURL;
    node.setAttribute("class", "epayco-button");
    node.setAttribute(
      "data-epayco-key",
      this.selectedBusinessUnit.payment_publickey
    );
    node.setAttribute("data-epayco-amount", this.totalTransaction.toString());
    node.setAttribute("data-epayco-name", "Mi Alfolí");
    node.setAttribute(
      "data-epayco-description",
      "Devolución de diezmos y ofrendas"
    );
    node.setAttribute("data-epayco-currency", "cop");
    node.setAttribute("data-epayco-country", "co");
    node.setAttribute("data-epayco-test", "true");
    node.setAttribute(
      "data-epayco-response",
      GlobalVariable.BASE_SERVER_URL + "transactionresponse"
    );
    node.setAttribute(
      "data-epayco-confirmation",
      GlobalVariable.BASE_API_URL + GlobalVariable.PAYMENT_CONFIRMATION_URL
    );
    node.setAttribute("data-epayco-extra1", this.selectedDistrict.code);
    node.setAttribute("data-epayco-extra2", this.selectedChurch.code);
    node.setAttribute(
      "data-epayco-extra3",
      this.envelopeForm.value.tithes === null
        ? 0
        : this.envelopeForm.value.tithes
    );
    node.setAttribute("data-epayco-extra4", this.totalOfferings.toString());
    node.setAttribute(
      "data-epayco-extra5",
      this.envelopeForm.value.offeringsTemple === null
        ? 0
        : this.envelopeForm.value.offeringsTemple
    );
    node.setAttribute(
      "data-epayco-extra6",
      this.envelopeForm.value.offeringsADRA === null
        ? 0
        : this.envelopeForm.value.offeringsADRA
    );
    node.setAttribute(
      "data-epayco-extra7",
      this.envelopeForm.value.offeringsOther === null
        ? 0
        : this.envelopeForm.value.offeringsOther
    );
    node.setAttribute(
      "data-epayco-extra8",
      this.envelopeForm.value.offeringsOtherDescription === null ||
        this.envelopeForm.value.offeringsOtherDescription === undefined
        ? " "
        : this.envelopeForm.value.offeringsOtherDescription
    );
    let extra9 =
      this.envelopeForm.value.offeringsGeneral === null
        ? "0"
        : this.envelopeForm.value.offeringsGeneral;
    extra9 += "@";
    extra9 +=
      this.envelopeForm.value.offeringsFirst === null
        ? "0"
        : this.envelopeForm.value.offeringsFirst;
    extra9 += "@";
    extra9 +=
      this.envelopeForm.value.offerings13th === null
        ? "0"
        : this.envelopeForm.value.offerings13th;
    extra9 += "@";
    extra9 +=
      this.envelopeForm.value.offeringsInvestment === null
        ? "0"
        : this.envelopeForm.value.offeringsInvestment;
    extra9 += "@";
    extra9 +=
      this.envelopeForm.value.offeringsSabbathSchool === null
        ? "0"
        : this.envelopeForm.value.offeringsSabbathSchool;
    extra9 += "@";
    extra9 +=
      this.envelopeForm.value.offeringsBirthday === null
        ? "0"
        : this.envelopeForm.value.offeringsBirthday;
    extra9 += "@";
    extra9 +=
      this.envelopeForm.value.offeringsGratitude === null
        ? "0"
        : this.envelopeForm.value.offeringsGratitude;
    node.setAttribute("data-epayco-extra9", extra9);
    document.getElementsByTagName("form")[1].appendChild(node);
    node.onload = () => {
      document.getElementsByTagName("button")[this.buttonIndex];
      setTimeout(this.clickEpaycoButton, 1000);
    };
  }

  clickEpaycoButton() {
    document.getElementsByTagName("button")[this.buttonIndex].click();
  }

  loadWompiScript() {
    this.isLoading = true;
    const reference = password.randomPassword({
      length: 6,
      characters: [password.upper, password.digits],
    });

    this.paymentService
      .getSecretIntegrityEncrypt(
        reference,
        (this.totalTransaction * 100).toString(),
        "COP",
        this.selectedBusinessUnit.payment_integrity
      )
      .subscribe(
        (p) => {
          this.secretIntegrityHash =
            p.results !== undefined ? p.results[0].hash : "";
        },
        (e) => this.launchMessage(e),
        () => {
          this.isLoading = false;

          let node = document.createElement("script");
          node.src = wompiURL;
          node.setAttribute("data-render", "button");
          node.setAttribute(
            "data-public-key",
            this.selectedBusinessUnit.payment_publickey
          );
          node.setAttribute("data-currency", "COP");
          node.setAttribute(
            "data-amount-in-cents",
            (this.totalTransaction * 100).toString()
          );
          node.setAttribute("data-reference", reference);
          node.setAttribute(
            "data-redirect-url",
            GlobalVariable.BASE_SERVERMIALFOLI_URL + "transactionresponse"
          );
          node.setAttribute(
            "data-signature:integrity",
            this.secretIntegrityHash
          );
          document.getElementsByTagName("form")[2].appendChild(node);
          node.onload = () => {
            document.getElementsByTagName("button")[this.buttonIndex];
            // setTimeout(this.clickWompiButton, 1000)
            this.createPayment(reference);
          };
        }
      );
  }

  clickWompiButton() {
    document.getElementsByTagName("button")[this.buttonIndex].click();
  }

  createPayment(reference: string) {
    let message = "";
    const submission = this.buildJSONToPostPayment(reference);
    this.paymentService.post(submission).subscribe(
      (p) => (message = p.message),
      (e) =>
        this.launchMessage(
          "Su alfolí no se pudo procesar en estos momentos. Por favor intente nuevamente"
        ),
      () => {
        this.isLoading = false;
        if (message === "Payment was created") {
          this.clickWompiButton();
        } else {
          this.launchMessage(
            "Su alfolí no se pudo procesar en estos momentos. Por favor intente nuevamente"
          );
        }
      }
    );
  }

  /** this method builds the json using the data enter in the form and selected assets */
  buildJSONToPostPayment(reference: string): any {
    const now = new Date();
    const month = now.getMonth() + 1;
    const x = {
      bu_code: this.selectedBusinessUnit.id,
      union_code: this.selectedUnion.id,
      bu_short_name: this.selectedBusinessUnit.short_name,
      bu_email: this.selectedBusinessUnit.email,
      district_name: this.selectedDistrict.name,
      church_email: this.selectedChurch.email,
      church_name: this.selectedChurch.name,
      district_code: this.selectedDistrict.code,
      church_code: this.selectedChurch.code,
      tithe:
        this.envelopeForm.value.tithes === null
          ? 0
          : this.envelopeForm.value.tithes,
      general_offering: this.totalOfferings.toString(),
      temple_offering:
        this.envelopeForm.value.offeringsTemple === null
          ? 0
          : this.envelopeForm.value.offeringsTemple,
      radiotv_offering:
        this.envelopeForm.value.offeringsRadioTV === null
          ? 0
          : this.envelopeForm.value.offeringsRadioTV,
      adra_offering:
        this.envelopeForm.value.offeringsADRA === null
          ? 0
          : this.envelopeForm.value.offeringsADRA,
      specific_offering:
        this.envelopeForm.value.offeringsOther === null
          ? 0
          : this.envelopeForm.value.offeringsOther,
      description_specific_offering:
        this.envelopeForm.value.offeringsOtherDescription === null ||
        this.envelopeForm.value.offeringsOtherDescription === undefined
          ? " "
          : this.envelopeForm.value.offeringsOtherDescription,
      payment_o_general:
        this.envelopeForm.value.offeringsGeneral === null
          ? "0"
          : this.envelopeForm.value.offeringsGeneral,
      payment_o_first:
        this.envelopeForm.value.offeringsFirst === null
          ? "0"
          : this.envelopeForm.value.offeringsFirst,
      payment_o_13th:
        this.envelopeForm.value.offerings13th === null
          ? "0"
          : this.envelopeForm.value.offerings13th,
      payment_o_investment:
        this.envelopeForm.value.offeringsInvestment === null
          ? "0"
          : this.envelopeForm.value.offeringsInvestment,
      payment_o_sabbathschool:
        this.envelopeForm.value.offeringsSabbathSchool === null
          ? "0"
          : this.envelopeForm.value.offeringsSabbathSchool,
      payment_o_birthday:
        this.envelopeForm.value.offeringsBirthday === null
          ? "0"
          : this.envelopeForm.value.offeringsBirthday,
      payment_o_gratitude:
        this.envelopeForm.value.offeringsGratitude === null
          ? "0"
          : this.envelopeForm.value.offeringsGratitude,
      customer_doctype: "CC",
      customer_document: this.envelopeForm.value.document,
      customer_name: this.envelopeForm.value.firstName,
      customer_lastname: this.envelopeForm.value.lastName,
      customer_email: this.envelopeForm.value.email,
      customer_phone: this.envelopeForm.value.phoneNumber,
      customer_movil: this.envelopeForm.value.phoneNumber,
      customer_country: "",
      customer_city: "",
      customer_address: "",
      customer_ip: "",
      reference: reference,
      invoice: "",
      payment_description: "",
      amount: this.totalTransaction.toString(),
      amount_available: "0",
      tax: "0",
      currency_code: "",
      bank_name: "",
      card_number: "",
      franchise: "",
      quotas: "",
      response: "AWAITING",
      response_code: "",
      approval_code: "",
      transaction_id: "",
      transaction_date:
        now.getFullYear() +
        "-" +
        month +
        "-" +
        now.getDate() +
        " " +
        now.getHours() +
        ":" +
        now.getMinutes() +
        ":" +
        now.getSeconds(),
      signature: "",
    };
    return x;
  }

  searchByChurch() {
    const dialogRef = this.dialog.open(ChurchSearchComponent, {
      width: "650px",
    });
    const sub = dialogRef.componentInstance.data.subscribe((data) => {
      this.setValues(data);
    });
    dialogRef.afterClosed().subscribe(() => {
      sub.unsubscribe();
    });
    // reset payment buttons to update public key
    document.getElementsByTagName("form")[1].innerHTML = "";
    document.getElementsByTagName("form")[2].innerHTML = "";
  }

  setValues(data: Church) {
    this.unionParameter = data.union_code;
    this.businessUnitParameter = data.bu_code;
    this.districtParameter = data.district_id;
    this.churchParameter = data.id;

    this.getAllUnion();
  }

  /** Launch message of the snackBar component */
  launchMessage(message: string) {
    this.errorMessage = "";
    this.isLoading = false;
    const action = "OK";
    this.snackBar.open(message, action, {
      duration: 5000,
    });
  }
}
