export const GlobalVariable = Object.freeze({
  APP_NAME: "Alfolí Adventista",
  // BASE_SERVER_URL: "http://localhost:4200/",
  // BASE_API_URL2: 'http://localhost:80/alfoli-rest-api/api/',
  BASE_SERVER_URL: "https://alfoliadventista.org/",
  BASE_SERVERMIALFOLI_URL: "https://mialfoliadventista.org/",
  BASE_API_URL: "https://alfoliadventista.org/api/",
  EPAYCO_API_URL: "https://api.secure.payco.co/",
  //WOMPI_API_URL: "https://sandbox.wompi.co/",
  WOMPI_API_URL: "https://production.wompi.co/",
  BASE_BLOBSTORAGE_URL: "https://dwsda.blob.core.windows.net/",
  SIGNATURE_BLOBCONTAINER_NAME: "signature",
  UNION_READ_EP: "sdaUnion/read.php",
  BUSINESSUNIT_READ_EP: "businessUnit/read.php",
  BUSINESSUNIT_READONE_EP: "businessUnit/readone.php",
  BUSINESSUNIT_READBYUNION_EP: "businessUnit/readByUnion.php",
  BUSINESSUNIT_SENDEMAILSUPPORT: "businessUnit/sendEmailSupport.php",
  BUSINESSUNIT_ATTACHFILE: "businessUnit/attachFile.php",
  DISTRICT_READ_EP: "district/read.php",
  DISTRICT_READBYBU_EP: "district/readByBU.php",
  CHURCH_READ_EP: "church/read.php",
  CHURCH_READBYDISTRICT_EP: "church/readByDistrict.php",
  PAYMENT_GENERATESECRETINTEGRITYHASH_URL:
    "payment/generatesecretintegrityhash.php",
  PAYMENT_CREATE_URL: "payment/create.php",
  PAYMENT_CONFIRMATION_URL: "payment/confirmation.php",
  ACCESSLOG_CREATE_EP: "accessLog/create.php",
  EPAYCO_READPAYMENT_URL: "validation/v1/reference/",
  WOMPI_READPAYMENT_URL: "v1/transactions/",
  DONOR_VALIDATE_EP: "donor/validate.php",
  DONOR_VALIDATECODE_EP: "donor/validatecode.php",
  DONOR_PASSWORDRESET_EP: "donor/passwordreset.php",
  DONOR_READONE_EP: "donor/readone.php",
  DONOR_CREATE_EP: "donor/create.php",
  DONOR_UPDATE_CHURCH_EP: "donor/updatechurch.php",
  DONOR_UPDATE_PASSWORD_EP: "donor/updatepassword.php",
  DNITYPE_READ_EP: "dniType/read.php",
  DONATIONCERTIFICATE_READBYDONOR_EP: "donationCertificate/readbydonor.php",
});
